<template lang="pug">
  vs-dropdown(
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
    :title="$t('pinned_column')"
  )
    i(
      class="fas fa-thumbtack"
      style="font-size: 15px;margin-left: 5px"
    )
    vs-dropdown-menu(
      style="z-index: 99999;"
    )
      ul(
        style="width: 200px; height: 150px;overflow: auto;"
      )
        li(
          v-for="(column, index) in columnData" :key="index"
          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          v-if="column.hide"
        )
          vs-checkbox(
            v-if="validateObject(column)"
            class="ml-2"
            v-model="column.pinned"
            @click="asignColumnDataPin(column.colId, column.pinned)"
          ) {{ column.traslateName.traslateName[$i18n.locale] }}
          vs-checkbox(
            v-else
            class="ml-2"
            v-model="column.pinned"
            @click="asignColumnDataPin(column.colId, column.pinned)"
          ) {{ $t('column.' + column.colId) }}
</template>
<script>

const NAME="PinnedColumn"

export default {
  name: NAME,
  props: {
    columnData: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    clearPinned () {
      this.pinned = null
    },
    validateObject(value) {
      if (value.hasOwnProperty('traslateName'))  {
        if (value.traslateName.hasOwnProperty('traslateName'))  {
          return typeof value.traslateName.traslateName === 'object'
        }
      }
    },
    asignColumnDataPin (keyShow, pinned) {
      let isPinned = null
      if (pinned == null) {
        isPinned = 'left'
      }
      this.$emit('asignColumnDataPin', keyShow, isPinned)
    }
  }
}
</script>