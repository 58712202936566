<template lang="pug">
a(
  class="cursor-pointer"
  :title="$t('auto_size')"
  @click="autoSizeAll"
)
  i(
      class="fas fa-arrows-alt-h"
      style="font-size: 15px;margin-left: 5px"
    )
</template>
<script>

const NAME="sizeFields"

export default {
  name: NAME,
  methods: {
    autoSizeAll() {
      this.$emit('autoSizeAll')
    }
  }
}
</script>