<template lang="pug">
div.button-alignment
  vs-dropdown(
    vs-trigger-click
    class="dropdown-button-container shadow-drop cursor-pointer"
  )
    vs-button(
      class="btnx"
      type="filled"
    ) {{ $t('devices.actions') }}
    vs-button(
      class="btn-drop"
      type="filled"
      icon="expand_more"
    )
    vs-dropdown-menu(
      style="z-index: 99999;"
    )
      vs-dropdown-item(
        v-if="is_import"
        @click="importSet()"
      ) {{ $t('vehicles.import') }}
      download-csv(
        v-if="is_exportable && !isClientModule"
        :data="csvValues"
        :labels="csvTitles"
        name="Archivo.csv"
        delimiter=";"
      )
        vs-dropdown-item(
        ) {{ $t('exportar') }}
      vs-dropdown-item(
        v-if="isClientModule && !is_exportable"
        divider
        @click="exportSetCustom()"
      ) {{ $t('exportar') }}
      vs-dropdown-item(
        v-if="is_exportable_custom"
        @click="exportSetCustom()"
      ) {{ $t('exportar') }}
      vs-dropdown-item(
        v-if="is_product"
        divider
        @click="addProduct()"
      ) {{ $t('servicios_tab.product') }}
      vs-dropdown-item(
        v-if="is_info"
        @click="infoService()"
      ) {{ $t('info_service') }}
      vs-dropdown-item(
        v-if="is_retry"
        @click="IsRetry()"
      ) {{ $t('retry') }}
      vs-dropdown-item(
        v-if="is_info_reload"
        @click="infoCediReload()"
      ) {{ $t('info_service_reload') }}
      vs-dropdown-item(
        v-if="is_on_cost"
        @click="addOnCost()"
      ) {{ $t('add_on_cost') }}
      vs-dropdown-item(
        v-if="is_info_additional"
        @click="InfoAdditional()"
      ) {{ $t('aditional_information') }}
      vs-dropdown-item(
        v-if="is_add"
        divider
        @click="add()"
      ) {{ $t('actions_bar.add') }}
      vs-dropdown-item(
        v-if="is_edit"
        @click="edit()"
      ) {{ $t('actions_bar.edit') }}
      vs-dropdown-item(
        v-if="is_company"
        @click="IsCompany()"
      ) {{ $t('actions_bar.company') }}
      vs-dropdown-item(
        v-if="is_duplicate"
        @click="duplicate()"
      ) {{ $t('actions_bar.duplicate') }}
      vs-dropdown-item(
        v-if="is_trash"
        @click="trash()"
      ) {{ $t('actions_bar.trash') }}
      vs-dropdown-item(
        v-if="is_activate"
        divider
        @click="setActive()"
      ) {{ $t('actions_bar.active') }}
      vs-dropdown-item(
        v-if="is_deactivate"
        @click="setDeactivate()"
      ) {{ $t('actions_bar.deactivate') }}
</template>
<script>
const NAME = "ActionsBar"

export default {
  name: NAME,
  props: {
    json_data: {
      type: Array,
      required: false,
      default: () => []
    },
    isClientModule: {
      type: Boolean,
      required: false,
      default: false
    },
    json_fields: {
      type: Object,
      required: false,
      default: () => {}
    },
    is_add: {
      type: Boolean,
      required: false,
      default: false
    },
    is_company: {
      type: Boolean,
      required: false,
      default: false
    },
    is_edit: {
      type: Boolean,
      required: false,
      default: false
    },
    is_trash: {
      type: Boolean,
      required: false,
      default: false
    },
    is_duplicate: {
      type: Boolean,
      required: false,
      default: false
    },
    is_activate: {
      type: Boolean,
      required: false,
      default: false
    },
    is_deactivate: {
      type: Boolean,
      required: false,
      default: false
    },
    is_info_additional: {
      type: Boolean,
      required: false,
      default: false
    },
    is_exportable: {
      type: Boolean,
      required: false,
      default: false
    },
    is_exportable_custom: {
      type: Boolean,
      required: false,
      default: false
    },
    is_on_cost: {
      type: Boolean,
      required: false,
      default: false
    },
    is_product: {
      type: Boolean,
      required: false,
      default: false
    },
    is_info_reload: {
      type: Boolean,
      required: false,
      default: false
    },
    is_info: {
      type: Boolean,
      required: false,
      default: false
    },
    is_import: {
      type: Boolean,
      required: false,
      default: false
    },
    is_retry: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      csvFilters: [],
      csvTitles: [],
      csvValues: []
    }
  },
  watch: {
    json_data(value){
      this.setFields(value)
    },
    '$i18n.locale'(){
      this.setFields(this.json_data)
    }
  },
  mounted() {
    this.setHeader(this.json_fields)
  },
  methods: {
    setHeader(data){
      var field = {}
      var filter = {}
      if(data){
          Object.keys(data).forEach(function(key){
          if(typeof data[key] === 'object' && data[key] !== null){
            field[key] = data[key].field
            filter[key] = data[key].filter
          }else{
            field[key] = data[key]
          }
        })
      }
      this.csvTitles = field
      this.csvFilters = filter
    },
    setFields(data){
      var result = []
      data.forEach(element=>{
        var filter = this.csvFilters
        var fields = this.csvTitles
        var typeDocument = this.typeDocument
        var yes = this.$t('yes')
        var not = this.$t('not')
        var obj = {}
        Object.keys(element).forEach(function(key){
          if(fields[key]){
            if(typeof element[key] === 'object' && element[key] !== null){
              obj[key] = element[key][filter[key]]
            } else if (typeof element[key] === 'boolean' && element[key] !== null){
              obj[key] = element[key] === true ? yes : not
            } else if (key === 'document_type'){
              obj[key] = typeDocument(element[key])
            }else{
              obj[key] = element[key]
            }
          }
        })
        result.push(obj)
      })
      this.csvValues = result
    },
    add() {
      this.$emit("add")
    },
    edit() {
      this.$emit("edit")
    },
    trash() {
      this.$emit("trash")
    },
    duplicate() {
      this.$emit("duplicate")
    },
    setActive() {
      this.$emit("active")
    },
    setDeactivate() {
      this.$emit("deactivate")
    },
    importSet() {
      this.$emit("import")
    },
    exportSetCustom() {
      if (this.isClientModule) {
        this.$emit("exportCustomClient")
      } else {
        this.$emit("exportCustom")
      }
    },
    addProduct() {
      this.$emit("addProduct")
    },
    infoService() {
      this.$emit("infoService")
    },
    infoCediReload() {
      this.$emit("infoCediReload")
    },
    addOnCost() {
      this.$emit("addOnCost")
    },
    InfoAdditional() {
      this.$emit("InfoAdditional")
    },
    IsRetry() {
      this.$emit("IsRetry")
    },
    IsCompany() {
      this.$emit("IsCompany")
    }
  }
}
</script>