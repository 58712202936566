<template lang="pug">
  vs-dropdown(
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
    :title="$t('show_column')"
  )
    i(
      class="fas fa-eye"
      style="font-size: 15px;margin-left: 5px;"
    )
    vs-dropdown-menu(
      style="z-index: 99999;"
    )
      ul(
        style="width: 200px; height: 150px;overflow: auto;"
      )
        li(
          v-for="(column, index) in columnData" :key="index"
          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          v-if="!column.checkboxSelection"
        )
          vs-checkbox(
            v-if="validateObject(column)"
            class="ml-2"
            v-model="column.hide"
            @click="asignColumnDataShow(column.colId, column.hide)"
          ) {{ column.traslateName.traslateName[$i18n.locale] }}
          vs-checkbox(
            v-else
            class="ml-2"
            v-model="column.hide"
            @click="asignColumnDataShow(column.colId, column.hide)"
          ) {{ $t('column.' + column.colId) }}
</template>

<script>

const NAME="ShowColumn"

export default {
  name: NAME,
  props: {
    columnData: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    validateObject(value) { 
      if (value.traslateName && value.traslateName.traslateName) {
        return typeof value.traslateName.traslateName === 'object'
      }
    },
    asignColumnDataShow(keyShow, hide) {
      this.$emit('asignColumnDataShow', keyShow, !hide)
    }
  }
}
</script>