export default {
  noRowsToShow: "show_not_data",
  contains: "ag-table.contains",
  notContains: "ag-table.not-contains",
  equals: "ag-table.equals",
  notEqual: "ag-table.not-equals",
  startsWith: 'ag-table.starts-with',
  endsWith: 'ag-table.ends-with',
  filterOoo: 'ag-table.filters',
  andCondition: 'ag-table.and',
  orCondition: 'ag-table.or',
  clearFilter: 'ag-table.clear'
}