<template lang="pug">
vs-dropdown(
  vs-trigger-click
  class="cursor-pointer"
  style="font-size: 9px;"
)
  div(
    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
  )
    span(
      class="mr-2"
    ) {{ pagesInfo }}
    feather-icon(
      icon="ChevronDownIcon"
      svgClasses="h-4 w-4"
    )
  vs-dropdown-menu(
    style="z-index: 99999;"
  )
    vs-dropdown-item(
      v-for="(size, index) in pageSizeOptions"
      @click="updatePageSize(size)"
      :key="index"
    )
      span {{size}}
</template>
<script>

const NAME="PaginationTop"

export default {
  name: NAME,
  props: {
    pageSizeOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    pagesInfo: {
      type: String,
      required: false
    }
  },
  methods: {
    updatePageSize(val) {
      this.$emit('updatePageSize', val)
    }
  }
}
</script>