<template lang="pug">
vs-dropdown(
  vs-trigger-click
  class="cursor-pointer"
  style="font-size: 9px;"
)
  div(
    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
  )
    span(
      class="mr-2"
      v-if="typeof(infoPage.name) === 'string'"
    ) {{ $t(infoPage.name) }}
    span(
      v-else
    ) {{ infoPage.name }}
    feather-icon(
      icon="ChevronDownIcon"
      style="font-size:10px;"
      svgClasses="h-4 w-4"
    )
  vs-dropdown-menu(
    style="z-index: 9999999;"
  )
    vs-dropdown-item(
      v-for="(size, index) in pageSizeOptions"
      @click="updatePageShow(size)"
      :key="index"
    )
      span(
        v-if="typeof(size.name) === 'string'"
        style="font-size:10px;"
      ) {{ $t(size.name) }}
      span(
        v-else
        style="font-size:10px;"
      ) {{ size.name }}
</template>
<script>
const NAME = "PaginationTop"

export default {
  name: NAME,
  data() {
    return {
      infoPage: {
        id: 1,
        name: 500,
        value: 500
      },
      pageSizeOptions: [
        {
          id: 1,
          name: 500,
          value: 500
        },
        {
          id: 2,
          name: 1000,
          value: 1000
        },
        {
          id: 3,
          name: 2000,
          value: 2000
        },
        {
          id: 4,
          name: "show_pagination",
          value: null
        }
      ]
    }
  },
  mounted() {
    this.updatePageShow(this.infoPage)
  },
  methods: {
    updatePageShow(val) {
      this.infoPage = val
      this.$emit("updatePageShow", val)
    }
  }
}
</script>